import { ICoreContext } from '@msdyn365-commerce/core';
import React from 'react';
import { ModalComponent } from '../modal';
import { ProductListProduct } from '../../product-list-component';
import { AllergensIconGrid } from '../../../allergens-icon-grid';
import { useGetAttributes } from '../use-get-attributes';
import { getLocale } from '../../locale';
import { sortAttributes } from '../../../../shared-utils/attributes';

export interface IAllergensModalProps {
    className: string;
    onClose: () => void;
    product: ProductListProduct;
    context: ICoreContext;
}

export const AllergensModal = (props: IAllergensModalProps) => {
    const { className, onClose, product, context } = props;
    const { loading, attributes } = useGetAttributes(product, context);
    const locale = getLocale(context.actionContext.requestContext.locale);

    if (loading) {
        return (
            <ModalComponent className={className} onClose={props.onClose}>
                <h2>{locale.loading}...</h2>
            </ModalComponent>
        );
    }

    const allergens = attributes
        ?.filter(x => x.ExtensionProperties
            ?.some(prop => prop.Key === 'ATTRGROUPNAME' && prop.Value?.StringValue === locale.allergens))
        .sort(sortAttributes);
    const contains = allergens.filter(x => x.TextValue === locale.contains);
    const containsTraces = allergens.filter(x => x.TextValue === locale.canContainTracesAttribute);

    return (
        <ModalComponent
            className={className}
            onClose={onClose}
        >
            {contains.length === 0 && containsTraces.length === 0 && (
                <>
                    <h4>{locale.allergens}</h4>
                    <div className={`${className}__description`}>
                        {locale.noAllergensDefined}
                    </div>
                </>
            )}

            <AllergensIconGrid
                context={context}
                attributes={attributes}
                type={'contains'}
            />

            <AllergensIconGrid
                context={context}
                attributes={attributes}
                type={'contains-traces'}
            />
        </ModalComponent>
    );
};
