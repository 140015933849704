import React, { useEffect } from "react";
import { Portal } from 'react-portal';
import classname from "classnames";

export interface IModalProps {
    className?: string;
    children: React.ReactNode;
    onClose?: () => void;
}

export const ModalComponent: React.FC<IModalProps> = (props: IModalProps) => {
    const { className, children, onClose } = props;
    const [mouseDontClose, setMouseDontClose] = React.useState(false);

    useEffect(() => {
        const handleKeyupEvent = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose?.();
            }
        };

        document.addEventListener('keyup', handleKeyupEvent);

        const body = document.querySelector('body');
        if (body) {
            body.style.overflow = 'hidden';
        }
        return () => {
            document.removeEventListener('keyup', handleKeyupEvent);

            if (body) {
                body.style.overflow = 'auto';
            }
        };
    }, [onClose]);

    const handleMouseUpOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (mouseDontClose) {
            setMouseDontClose(false);
            return;
        }
        onClose?.();
    };

    const handleMouseDownInside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setMouseDontClose(true);
    };

    const handleMouseUpInside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        setMouseDontClose(false);
    };

    return (
        <Portal>
            <div className={classname("dlvry-modal-wrapper", className)} onMouseUp={handleMouseUpOutside}>
                <div className="dlvry-modal-container" onMouseDown={handleMouseDownInside} onMouseUp={handleMouseUpInside}>
                    <button className='dlvry-modal-container__close' onClick={() => onClose?.()}>
                        <i className='fa-solid fa-x'></i>
                    </button>
                    {children}
                </div>
            </div>
        </Portal>
    );
};
