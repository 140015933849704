import { ICoreContext } from '@msdyn365-commerce/core';
import nbNO from './nb-NO.json';
import enUS from './en-US.json';

export function getLocale(langOrContext: string | ICoreContext): typeof nbNO {
    const lang = typeof langOrContext === 'string' ? langOrContext : langOrContext.request.locale;

    switch (lang) {
        case 'nb-NO':
            return nbNO;
        case 'en-US':
            return {...nbNO, ...enUS};
        default:
            return nbNO;
    }
}

export const formatString = (...args: any[]) => {
    const str = args[0];
    const params = args.filter((arg, index) => index !== 0);
    if (!str) {
        return "";
    }
    return str.replace(/%s[0-9]+/g, (matchedStr: { replace: (arg0: string, arg1: string) => number; }) => {
        const variableIndex = matchedStr.replace("%s", "") - 1;
        return params[variableIndex];
    });
};