import { useEffect, useState } from 'react';
import { ICoreContext } from '@msdyn365-commerce/core';
import { ProductListProduct } from '../product-list-component';
import { AttributeValue } from '@msdyn365-commerce/retail-proxy';
import getSelectedVariantActionDataAction, { SelectedVariantInput } from '@msdyn365-commerce-modules/retail-actions/dist/lib/get-selected-variant';
import { getAttributesForSelectedVariantAction, AttributesForSelectedVariantInput } from '../../../actions/get-attributes-for-selected-variant';

export const useGetAttributes = (product: ProductListProduct, context: ICoreContext) => {
    const [loading, setLoading] = useState(true);
    const [attributes, setAttributes] = useState<AttributeValue[]>([]);

    useEffect(() => {
        const retrieveAttributes = async () => {
            if (!product.RecordId) {
                return;
            }

            const selectedVariantInput = new SelectedVariantInput(
                product.RecordId,
                context.actionContext.requestContext.apiSettings.channelId,
                undefined,
                undefined,
                context.request);
            const variant = await getSelectedVariantActionDataAction(selectedVariantInput, context.actionContext);

            if (!variant) {
                return;
            }

            const attributesInput = new AttributesForSelectedVariantInput(
                context.request.locale,
                product.RecordId,
                context.actionContext.requestContext.apiSettings.channelId,
                variant,
                undefined
            );
            const attributes: AttributeValue[] = await getAttributesForSelectedVariantAction(attributesInput, context.actionContext);

            setAttributes(attributes);
            setLoading(false);
        };
        retrieveAttributes().catch((error) => console.error(error));
    }, []);

    return { loading, attributes };
};
