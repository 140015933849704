import React from 'react';
import { ICoreContext } from '@msdyn365-commerce/core';
import { AttributeValue } from '@msdyn365-commerce/retail-proxy';
import { ProductListProduct } from '../../product-list-component';
import classnames from 'classnames';
import { ModalComponent } from '../modal';
import { useGetAttributes } from '../use-get-attributes';
import { AllergensIconGrid } from '../../../allergens-icon-grid';
import { getLocale } from '../../locale';
import { IAttributeGroupMap, addCelsiusSign, getAttributeStringOrDefault, getAttributesLocale, parseAttributesMap } from '../../../../shared-utils/attributes';

export interface IProductInformationModalProps {
    className: string;
    onClose: () => void;
    product: ProductListProduct;
    context: ICoreContext;
}

export interface IProductInformationTabProps {
    className: string;
    attributes: AttributeValue[];
    productInformation: {
        name: string,
        value: string
    }[] | undefined;
    context: ICoreContext;
}

export interface INutritionalInformationTabProps {
    className: string;
    ingredients: string;
    nutritionalInformation: {
        name: string,
        value: string
    }[] | undefined;
    context: ICoreContext;
}

const createProductInformationAttributesMap = (attributesLocale: any): IAttributeGroupMap[] => [
    {
        type: 'default',
        group: `${attributesLocale.groups.productInformation}`,
        attributes: [
            {
                name: attributesLocale.temperatureMin,
                transform: addCelsiusSign
            },
            {
                name: attributesLocale.temperatureMax,
                transform: addCelsiusSign
            },
            attributesLocale.gtin,
            attributesLocale.countryOfOrigin,
            attributesLocale.quantity,
            {
                name: attributesLocale.temperatureMax,
                label: attributesLocale.labels.temperatureZone,
                transform: (value) => {
                    const maxTemp = Number(value);

                    if (Number.isNaN(maxTemp)) {
                        return 'Error';
                    }

                    if (maxTemp < 0) {
                        return attributesLocale.frozen;
                    } else if (maxTemp <= 4) {
                        return attributesLocale.cool;
                    } else {
                        return attributesLocale.dry;
                    }
                }
            },
            {
                name: attributesLocale.alcoholpercentage,
                hideIfEmpty: true,
                transform: value => value ? `${value}%` : value
            }
        ]
    }
];

const ProductInformationTab = (props: IProductInformationTabProps) => {
    const { className, attributes, productInformation, context } = props;
    const locale = getLocale(context.actionContext.requestContext.locale);

    return (
        <div className={className}>
            <AllergensIconGrid
                context={context}
                attributes={attributes}
                type={'contains'}
            />

            <AllergensIconGrid
                context={context}
                attributes={attributes}
                type={'contains-traces'}
            />

            <h4>{locale.productInformationTitle}</h4>
            <table>
                {productInformation?.map((x) => (
                    <tr key={x.name.toLowerCase()}>
                        <td>{x.name}:</td>
                        <td>{x.value}</td>
                    </tr>
                ))}
            </table>
        </div>
    );
};

const NutritionalInformationTab = (props: INutritionalInformationTabProps) => {
    const { className, ingredients, nutritionalInformation, context } = props;
    const locale = getLocale(context.actionContext.requestContext.locale);

    return (
        <div className={className}>
            {ingredients && (
                <>
                    <h4>{locale.ingredients}</h4>
                    <div className={`${className}__ingredients`}>
                        {ingredients}
                    </div>
                </>
            )}

            {nutritionalInformation && nutritionalInformation.length > 0 && (
                <>
                    <h4>{locale.nutritionalContent}</h4>
                    <table>
                        {nutritionalInformation.map((x) => (
                            <tr key={x.name.toLowerCase()}>
                                <td>{x.name}:</td>
                                <td>{x.value}</td>
                            </tr>
                        ))}
                    </table>
                </>
            )}
        </div>
    );
};

export const ProductInformationModal = (props: IProductInformationModalProps) => {
    const { className, product, context } = props;
    const [tab, setTab] = React.useState(0);
    const { loading, attributes } = useGetAttributes(product, context);
    const locale = getLocale(context.actionContext.requestContext.locale);

    if (loading) {
        return (
            <ModalComponent className={className} onClose={props.onClose}>
                <h2>{locale.loading}...</h2>
            </ModalComponent>
        );
    }

    const ingredients = getAttributeStringOrDefault(attributes, locale.ingredients, '');
    const attributesLocale = getAttributesLocale(context.actionContext.requestContext.locale);

    const productInformationAttributesMap = parseAttributesMap(context, attributes, createProductInformationAttributesMap(attributesLocale));
    const productInformation = productInformationAttributesMap.find(x => x.name === `${attributesLocale.groups.productInformation}`);

    const attributesMap = parseAttributesMap(context, attributes);
    const nutrients = attributesMap.find(x => x.name === attributesLocale.groups.nutrients);
    const hasNutritionalInformation = ingredients || nutrients && nutrients.attributes.length > 0;

    return (
        <ModalComponent
            className={className}
            onClose={props.onClose}
        >
            <h3 className={`${className}__header`}>{product.Name} - {product.ItemId}</h3>

            <div className={`${className}__tab-container`}>
                <button
                    className={classnames(`${className}__tab`, {
                        selected: tab === 0
                    })}
                    onClick={() => setTab(0)}
                >
                    {locale.productInformationTitle}
                </button>
                {hasNutritionalInformation && (
                    <button
                        className={classnames(`${className}__tab`, {
                            selected: tab === 1
                        })}
                        onClick={() => setTab(1)}
                    >
                        {locale.nutritionalContent}
                    </button>
                )}
            </div>

            {tab === 0 && (
                <ProductInformationTab
                    className={`${className}__product-information`}
                    attributes={attributes}
                    productInformation={productInformation?.attributes}
                    context={context}
                />
            )}

            {tab === 1 && (
                <NutritionalInformationTab
                    className={`${className}__nutritional-information`}
                    ingredients={ingredients}
                    nutritionalInformation={nutrients?.attributes}
                    context={context}
                />
            )}
        </ModalComponent>
    );
};
