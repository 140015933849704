import React from 'react';
import { getAsset, IRequestContext } from '@msdyn365-commerce/core';

export const getIcon = (name: string | undefined, context: IRequestContext): JSX.Element => {
    switch (name) {
        case 'Gluten':
        case 'Spelt gluten':
        case 'Bygg gluten':
        case 'Havre gluten':
        case 'Hvete gluten':
        case 'Rug gluten':
        case 'Khorasanhvete gluten':
        case 'Barley gluten':
        case 'Oat gluten':
        case 'Wheat gluten':
        case 'Rye gluten':
        case 'Khorasan wheat gluten':
            return <i className='fa-solid fa-wheat'></i>;
        case 'Nøtter':
        case 'Hasselnøtter':
        case 'Valnøtter':
        case 'Macademiannøtter':
        case 'Mandler':
        case 'Paranøtter':
        case 'Peanøtter':
        case 'Pekannøtter':
        case 'Pistasienøtter':
        case 'Kasjunøtter':
        case 'Nuts':
        case 'Hazelnuts':
        case 'Walnuts':
        case 'Macadamia nuts':
        case 'Almonds':
        case 'Brazil nuts':
        case 'Peanuts':
        case 'Pecan nuts':
        case 'Pistachio nuts':
        case 'Cashew nuts':
            return <i className='fa-solid fa-chestnut'></i>;
        case 'Sesamfrø':
        case 'Soya':
        case 'Sesame seeds':
        case 'Soy':
            return <i className='fa-solid fa-seedling'></i>;
        case 'Selleri':
        case 'Celery':
            return <i className='fa-solid fa-leaf'></i>;
        case 'Egg':
            return <i className='fa-solid fa-egg'></i>;
        case 'Fisk':
        case 'Fish':
            return <i className='fa-solid fa-fish'></i>;
        case 'Melk':
        case 'Milk':
            return <img src={getAsset('icons/allergens/milk.svg', context)} />;
        case 'Sennep':
        case 'Mustard':
            return <i className='fa-solid fa-jar'></i>;
        default:
            return <i className='fa-solid fa-question'></i>;
    }
};
